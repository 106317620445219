import { createContext, useContext, useState } from "react";
import { makeApiRequest } from "middleware/api";
import { useHistory } from "react-router-dom";

// Create the authentication context
const AuthContext = createContext();

// Custom hook for accessing the authentication context
export function useAuth() {
  return useContext(AuthContext);
}

// Provider component to wrap around the app and provide authentication context
export function AuthProvider({ children }) {
  const history = useHistory();
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [profile, setProfile] = useState({});

  // Function to check if the user is authenticated
  const isAuthenticated = async () => {
    try {
      if (!token) {
        return false; // If there's no token, the user is not authenticated
      }

      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      if (response?.response?.status === 401) {
        return false;
      }
      else{
        return true
      }
    } catch (error) {
      return false;
    }
  };

  const isVerified = async () => {
    try {
      if (!token) {
        return false; // If there's no token, the user is not verified
      }

      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response?.response?.status === 424) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  // Function to set the token when the user logs in
  const login = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  // Function to clear the token when the user logs out
  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  // Provide the authentication state and functions to the app
  const authContextValue = {
    token,
    isAuthenticated,
    login,
    logout,
    isVerified,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}
