// api.js

import axios from "axios";

// const API_BASE_URL = "http://localhost:8000/api"; // Replace this with your actual API base URL
const API_BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`; // Replace this with your actual API base URL

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const makeApiRequest = async (url, method, data, headers) => {
  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return error
  }
};
