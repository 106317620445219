import { Box, Select, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import Card from "components/card/Card.js";
import wallets from "./dummy.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function Withdrawals() {
  const [wallet, setWallet] = useState("");
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);

  const [selectedWithdrawalMethod, setSelectedWithdrawalMethod] = useState("");

  const [selectedWithdrawalMethodId, setSelectedWithdrawalMethodId] =
    useState(null);
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");

  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/withdrawal-methods", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setWithdrawalMethods(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let data = {
      withdrawal_method_id: selectedWithdrawalMethodId,
      amount: amount,
      // address: wallet,
      otp: otp,
    };

    try {
      let response = await makeApiRequest("/place-withdrawal", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Withdrawal Successful");

        // console.log(response);
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.withdrawal_method_id &&
            setMessage(response.response.data.errors.withdrawal_method_id[0]);
          response.response.data.errors.amount &&
            setMessage(response.response.data.errors.amount[0]);
          response.response.data.errors.otp &&
            setMessage(response.response.data.errors.otp[0]);
          response.response.data.errors.address &&
            setMessage(response.response.data.errors.address[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleOTP = async (event) => {
    event.preventDefault();
    setDisableButton(true);

    try {
      let response = await makeApiRequest(
        "/request-withdrawal-otp",
        "POST",
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Withdrawal OTP has been sent to your email");
      } else {
        setStatus("error");
        setMessage("An Error Ocurred!");
        setDisableButton(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSelected = (event, id, name) => {
    event.preventDefault();
    // console.log(id, name);

    setSelectedWithdrawalMethod(name);
    setSelectedWithdrawalMethodId(id);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card className="min-vh-75" mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Withdraw Funds from Your Wallet</h2>
          <div className="body py-3">
            {selectedWithdrawalMethod != "" ? (
              <Row className="mx-auto justify-content-center">
                <Col md={6}>
                  <div className="d-flex justify-content-center">
                    Account Balance:{" "}
                    {`$ ${
                      profile?.wallet_balance == undefined
                        ? "---"
                        : profile?.wallet_balance
                    }`}
                  </div>
                  <div className="alert alert-info">
                    Withdrawal Method selected:{" "}
                    <span className="fw-bolder">
                      {selectedWithdrawalMethod}
                    </span>
                  </div>
                  <Form onSubmit={handleFormSubmit}>
                    <FormGroup>
                      <Label for="amountToSend">Amount to Withdraw</Label>
                      <Input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter Amount to Withdraw"
                        required
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="amountToSend">
                        Enter {selectedWithdrawalMethod} Wallet Address
                      </Label>
                      <Input
                        type="text"
                        id="otp"
                        value={wallet}
                        onChange={(e) => setWallet(e.target.value)}
                        placeholder="Enter Wallet Address"
                        required
                      />
                    </FormGroup> */}
                    {profile?.withdrawal_otp_status == 1 && (
                      <FormGroup>
                        <Label for="amountToSend">Enter OTP</Label>
                        <Input
                          type="text"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                        />
                        <Button
                          className="w-100 mt-1"
                          color="success"
                          type="button"
                          onClick={handleOTP}
                          disabled={disableButton}
                        >
                          {disableButton
                            ? "Withdrawal OTP sent"
                            : "Request OTP"}
                        </Button>
                      </FormGroup>
                    )}
                    <hr />
                    <Button
                      className="w-100 py-3"
                      color="primary"
                      type="submit"
                    >
                      Withdraw
                    </Button>
                  </Form>
                </Col>
              </Row>
            ) : (
              <SimpleGrid
                mb="20px"
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                spacing={{ base: "20px", xl: "20px" }}
              >
                {withdrawalMethods.map((item, key) => {
                  let name = `${item.name} (${item.symbol})`;

                  return (
                    <Card
                      key={key}
                      className="min-vh-50 shadow"
                      mb={{ base: "0px", "2xl": "20px" }}
                    >
                      <p className="h4 text-warning font-weight-bold text-center">
                        {name}
                      </p>

                      <div className="body py-1">
                        <Row className="py-1">
                          <Col md={9}>Minimum Withdrawal:</Col>
                          <Col className="d-flex justify-content-end" md={3}>
                            ${item.min_amount}
                          </Col>
                        </Row>
                        <Row className="py-1">
                          <Col md={9}>Maximum Withdrawal:</Col>
                          <Col className="d-flex justify-content-end" md={3}>
                            ${item.max_amount}
                          </Col>
                        </Row>
                        <Row className="py-1">
                          <Col md={9}>Charge Type:</Col>
                          <Col className="d-flex justify-content-end" md={3}>
                            ${item.min_return}
                          </Col>
                        </Row>
                        <Row className="py-1">
                          <Col md={9}>Charges Amount:</Col>
                          <Col className="d-flex justify-content-end" md={3}>
                            ${item.max_return}
                          </Col>
                        </Row>
                        <Row className="py-1">
                          <Col md={9}>Duration:</Col>
                          <Col className="d-flex justify-content-end" md={3}>
                            {item.duration} {item.duration_label}
                          </Col>
                        </Row>
                        <Row className="pt-4">
                          <Col md={12}>
                            <Button
                              className="w-100"
                              color="success"
                              type="button"
                              onClick={(event) =>
                                handleSelected(
                                  event,
                                  item.withdrawal_method_id,
                                  name
                                )
                              }
                            >
                              Request Withdrawal
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </SimpleGrid>
            )}
          </div>
        </Card>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
      </SimpleGrid>
    </Box>
  );
}
