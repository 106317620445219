import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Card from "components/card/Card.js";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function TransferFunds() {
  const [receiverEmail, setReceiverEmail] = useState("");
  const [amountToSend, setAmountToSend] = useState("");
  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData)

        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let data = {
      email: receiverEmail,
      amount: amountToSend,
    };

    try {
      let response = await makeApiRequest("/internal-transfer", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Transfer Successful");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.email &&
            setMessage(response.response.data.errors.email[0]);
          response.response.data.errors.amount &&
            setMessage(response.response.data.errors.amount[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card className="min-vh-75" mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Transfer Funds to Another User</h2>
          <div className="body py-3">
            <Row className="mx-auto justify-content-center">
              <Col md={6}>
                <div className="d-flex justify-content-center">
                  Account Balance:{" "}
                  {`$ ${
                    profile?.wallet_balance == undefined
                      ? "---"
                      : profile?.wallet_balance
                  }`}
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Label for="receiverEmail">Receiver Email</Label>
                    <Input
                      type="email"
                      id="receiverEmail"
                      value={receiverEmail}
                      onChange={(e) => setReceiverEmail(e.target.value)}
                      placeholder="Enter receiver's email"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="amountToSend">Amount to Send</Label>
                    <Input
                      type="number"
                      id="amountToSend"
                      value={amountToSend}
                      onChange={(e) => setAmountToSend(e.target.value)}
                      placeholder="Enter amount to send"
                      required
                    />
                  </FormGroup>
                  <Button className="w-100" color="primary" type="submit">
                    Send
                  </Button>
                  {showAlert && (
                    <Alert
                      className="w-100 p-4"
                      message={message}
                      status={status}
                    />
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
