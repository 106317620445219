import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Card from "components/card/Card.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import dummyCryptos from "./withdrawalMethods";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function Profile() {
  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");
  const [text, setText] = useState("");
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);

  // State variables for Profile Tab
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");

  // State variables for Security Tab
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // State variable for Others Tab
  const [otpConfirmation, setOtpConfirmation] = useState(false);
  const [sendProfitEmail, setSendProfitEmail] = useState(false);
  const [sendExpirationEmail, setSendExpirationEmail] = useState(false);

  // const [bankName, setBankName] = useState("");
  // const [accountName, setAccountName] = useState("");
  // const [accountNumber, setAccountNumber] = useState("");
  // const [swiftCode, setSwiftCode] = useState("");
  // const [selectedCrypto, setSelectedCrypto] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [address_ids, setAddress_ids] = useState([]);

  const [newWithdrawalMethods, setNewWithdrawalMethods] = useState(null);

  let token = localStorage.getItem("token");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/withdrawal-settings", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setWithdrawalMethods(responseData);
        setAddresses(responseData);
        const ids = responseData.map((item) => item.withdrawal_setting_id);
        setAddress_ids(ids);
      }
    } catch (error) {
      // console.log(error);
    }

    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;

        setProfile(responseData);

        setFullName(responseData?.full_name);
        setNationality(responseData?.country);
        setPhone(responseData?.phone_number);

        setOtpConfirmation(responseData?.withdrawal_otp_status);
        setSendProfitEmail(responseData?.profit_mail_status);
        setSendExpirationEmail(responseData?.investment_expiry_mail_status);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    // Handle profile form submission here
    // console.log("Full Name:", fullName);
    // console.log("Phone:", phone);
    // console.log("Nationality:", nationality);

    let data = {
      full_name: fullName,
      phone_number: phone,
      country: nationality,
    };

    try {
      let response = await makeApiRequest(
        "/update-personal-info",
        "POST",
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setShowAlert(true);

      // console.log(response);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Profile Update Successful");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.full_name &&
            setMessage(response.response.data.errors.full_name[0]);
          response.response.data.errors.phone_number &&
            setMessage(response.response.data.errors.phone_number[0]);
          response.response.data.errors.country &&
            setMessage(response.response.data.errors.country[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
      setStatus("error");
      setMessage("An Error Ocurred");
    }
  };

  const handleWithdrawalSubmit = async (event) => {
    event.preventDefault();

    // console.log(addresses, address_ids)

    let data = {
      withdrawal_setting_id: address_ids,
      address: addresses,
    };

    try {
      let response = await makeApiRequest(
        "/update-withdrawal-settings",
        "POST",
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Withdrawals Settings Updated");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSecuritySubmit = async (event) => {
    event.preventDefault();

    let data = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: confirmPassword,
    };

    try {
      let response = await makeApiRequest("/update-password", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      // console.log(response);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Password Update Successful");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.old_password &&
            setMessage(response.response.data.errors.old_password[0]);
          response.response.data.errors.new_password &&
            setMessage(response.response.data.errors.new_password[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
      setStatus("error");
      setMessage("An Error Ocurred");
    }
  };

  const handleOthersSubmit = async (event) => {
    event.preventDefault();

    let data = {
      profit_mail_status: Number(sendProfitEmail),
      withdrawal_otp_status: Number(otpConfirmation),
      investment_expiry_mail_status: Number(sendExpirationEmail),
    };

    try {
      let response = await makeApiRequest(
        "/update-other-setting",
        "POST",
        data,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Settings Update Succesful");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3 d-flex"
          >
            <Tab eventKey="profile" title="Profile">
              <h2 className="h2 text-center">Personal Settings</h2>
              <Form onSubmit={handleProfileSubmit}>
                <FormGroup>
                  <Label for="fullName">Full Name</Label>
                  <Input
                    type="text"
                    id="fullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    id="email"
                    value={profile?.email}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="nationality">Address (Street/City/Country)</Label>
                  <Input
                    type="text"
                    id="nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  Update Profile
                </Button>
              </Form>
            </Tab>

            <Tab eventKey="withdrawals" title="Withdrawals">
              <h2 className="h2 text-center">Withdrawal Settings</h2>
              <Form onSubmit={handleWithdrawalSubmit}>
                {/* <div className="text-muted mb-2 h4 ">Bank Account</div>
                <FormGroup>
                  <Label for="bankName">Bank Name</Label>
                  <Input
                    type="text"
                    id="bankName"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="accountName">Account Name</Label>
                  <Input
                    type="text"
                    id="accountName"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="accountNumber">Account Number</Label>
                  <Input
                    type="text"
                    id="accountNumber"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="swiftCode">Swift Code</Label>
                  <Input
                    type="text"
                    id="swiftCode"
                    value={swiftCode}
                    onChange={(e) => setSwiftCode(e.target.value)}
                    required
                  />
                </FormGroup>
                <hr /> */}
                <div className="text-muted mb-2 mt-4 h4">Cryptocurrency</div>
                {withdrawalMethods?.map((crypto, key) => (
                  <FormGroup key={key}>
                    <Label for="cryptoSelect">{crypto.name}</Label>
                    <Input
                      type="text"
                      className="cryptoSelect"
                      value={addresses[key]?.address}
                      required
                      onChange={(e) => {
                        const newCrypto = [...addresses];
                        newCrypto[key] = e.target.value;
                        setAddresses(newCrypto);
                      }}
                      placeholder={`Enter your ${crypto.name} address`}
                    />
                  </FormGroup>
                ))}

                <Button color="primary" type="submit">
                  Save Settings
                </Button>
              </Form>
            </Tab>
            <Tab eventKey="security" title="Security">
              <h2 className="h2 text-center">Change Password</h2>
              <Form onSubmit={handleSecuritySubmit}>
                <FormGroup>
                  <Label for="oldPassword">Old Password</Label>
                  <Input
                    type="password"
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="newPassword">New Password</Label>
                  <Input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword">Confirm Password</Label>
                  <Input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  Update Password
                </Button>
              </Form>
            </Tab>
            <Tab eventKey="others" title="Others">
              <h2 className="h2 text-center">Other Settings</h2>
              <Form onSubmit={handleOthersSubmit}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={otpConfirmation}
                      onChange={(e) => setOtpConfirmation(e.target.checked)}
                    />{" "}
                    Send confirmation OTP to my email when withdrawing my funds
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={sendProfitEmail}
                      onChange={(e) => setSendProfitEmail(e.target.checked)}
                    />{" "}
                    Send me email when I get profit
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={sendExpirationEmail}
                      onChange={(e) => setSendExpirationEmail(e.target.checked)}
                    />{" "}
                    Send me email when my investment plan expires
                  </Label>
                </FormGroup>
                <Button color="primary" type="submit">
                  Save Settings
                </Button>
              </Form>
            </Tab>
          </Tabs>
        </Card>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
      </SimpleGrid>
    </Box>
  );
}
