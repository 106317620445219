import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./middleware/authContext";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <ThemeEditorProvider>
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/dashboard`} component={AdminLayout} />
            <Route path={`/rtl`} component={RtlLayout} />
            <Redirect from="/" to="/dashboard/overview" />
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </ThemeEditorProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
