import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Card from "components/card/Card.js";
import dummy from "./dummy.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function InvestmentHistory() {
  const [investment, setInvestment] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const [current, setCurrent] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/investment-history", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setInvestment(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const toggleModal = (event, id) => {
    setModalOpen(!isModalOpen);

    let indexToToggle = investment.findIndex((item) => item.plan_id === id);
    setCurrent(investment[indexToToggle]);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Investment History</h2>
          <div className="table-responsive">
            <Table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Plan</th>
                  <th>Amount</th>
                  <th>Expected Returns</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {investment?.map((item, key) => {
                  let statusBadge = "";

                  switch (item.status) {
                    case "completed":
                      statusBadge = "badge text-bg-success";
                      break;
                    case "pending":
                      statusBadge = "badge text-bg-warning";
                      break;
                    case "rejected":
                      statusBadge = "badge text-bg-danger";
                      break;
                    default:
                      statusBadge = "badge text-bg-info"; // Default color if the status is not recognized
                  }

                  return (
                    <tr key={key}>
                      <td>{item.confirmed_date}</td>
                      <td>{item.plan_name}</td>
                      <td>${item.amount}</td>
                      
                      <td className="text-warning">
                        ${item.total_profit}
                      </td>
                      <td>
                        {item.duration_label}
                      </td>
                      <td>
                        <div className={statusBadge}>{item.status}</div>
                      </td>
                      <td>
                        <Button
                          className="w-100"
                          color="success"
                          type="button"
                          onClick={(event) => toggleModal(event, item.plan_id)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      </SimpleGrid>
      {showAlert && (
        <Alert className="w-100 p-4" message={message} status={status} />
      )}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Investment Details</ModalHeader>
        <ModalBody>
          <Container>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Name:</div>
              </Col>
              <Col md={4}>
                <Text className="text-primary fw-bolder">{current?.plan_name}</Text>
              </Col>
            </Row>
            {/* <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Rate:</div>
              </Col>
              <Col md={4}>
                <Text>{current?.rate}%</Text>
              </Col>
            </Row> */}
            {/* <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Min Amount:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.min_amount}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Max Amount:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.max_amount}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Min Return:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.min_return}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Max Return:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.max_return}</Text>
              </Col>
            </Row> */}
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Amount:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.amount}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Bonus:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.bonus}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Duration:</div>
              </Col>
              <Col md={4}>
                <Text>
                 {current?.duration_label}
                </Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Confirmed Date:</div>
              </Col>
              <Col md={4}>
                <Text>{current?.confirmed_date}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Days:</div>
              </Col>
              <Col md={4}>
                <Text>{current?.days_count} days</Text>
              </Col>
            </Row>
            
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Returns:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.total_profit}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Withdrawable:</div>
              </Col>
              <Col md={4}>
                <Text>${current?.withdrawalable_amount}</Text>
              </Col>
            </Row>
            <Row className="py-1">
              <Col md={8}>
                <div className="fw-bold">Status:</div>
              </Col>
              <Col md={4}>
                <Text className="fw-bolder text-info" >{current?.status}</Text>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Box>
  );
}
