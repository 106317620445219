import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { makeApiRequest } from "middleware/api";
import Alert from "middleware/alert";
import SuccessfulLoginModal from "./modal";
import logo from "assets/img/logo.png";

function SignUp() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let ref = query.get("ref");

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSignUpSubmit = async (event) => {
    event.preventDefault();

    setDisableButton(true);

    const formData = new FormData(event.target);
    let signUpData = Object.fromEntries(formData);
    signUpData.privacy_status = 1;
    signUpData.ref_id = ref

    try {
      let response = await makeApiRequest("/register", "POST", signUpData);

      setShowAlert(true);

      if (response.status === 200) {
        const responseData = response.data;
        setStatus("success");
        setMessage("Sign Up Successful! Check your email to verify");

        localStorage.setItem("token", responseData.data.token);
      } else {
        setStatus("error");
        if (response?.response?.status === 422) {
          let errors = response.response.data.errors;

          errors?.email && setMessage(errors?.email[0]);
          errors?.username && setMessage(errors?.username[0]);
          errors?.password && setMessage(errors?.password[0]);
          errors?.full_name && setMessage(errors?.full_name[0]);
          errors?.phone_number && setMessage(errors?.phone_number[0]);
          errors?.country && setMessage(errors?.country[0]);
          errors?.privacy_status && setMessage(errors?.privacy_status[0]);
        } else if (response?.response?.status === 401) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    // Hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (status == "success") {
        window.location.href = "/auth/sign-in";
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx="auto"
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px", sm: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <div className="d-flex justify-content-center w-100">
        <img src={logo} className="img-fluid" alt="logo" />
      </div>
      <Box me="auto">
        <Heading className="" color={textColor} fontSize="36px" mb="10px">
          Sign Up
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Create an account with us to get started!
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleSignUpSubmit}>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Full Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              name="full_name"
              placeholder="John Doe"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              name="email"
              placeholder="johndoe@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Username<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              name="username"
              placeholder="johndoe123"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Phone Number<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="tel"
              name="phone_number"
              placeholder="123-456-7890"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Country<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              name="country"
              placeholder="Your country"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={showPassword ? "text" : "password"}
                name="password"
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handlePasswordVisibility}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Confirm Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Confirm your password"
                mb="24px"
                size="lg"
                type={showPassword ? "text" : "password"}
                name="password_confirmation"
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handlePasswordVisibility}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Privacy Status<Text color={brandStars}>*</Text>
            </FormLabel>
            <Checkbox
              id="privacy_status"
              colorScheme="brandScheme"
              name="privacy_status"
              mb="24px"
              size="lg"
              fontWeight="500"
              isRequired={true}
            >
              I agree to the privacy policy
            </Checkbox>
          </FormControl>

          <Button
            disabled={disableButton}
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50px"
            mb="24px"
          >
            Sign Up
          </Button>

          {showAlert && (
            <Alert className="w-100 p-4" message={message} status={status} />
          )}
        </form>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            Already registered?
            <NavLink to="/auth/sign-in">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Sign In
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
