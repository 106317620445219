import { Alert, AlertIcon, Box } from "@chakra-ui/react";

import React from "react";

export default function AlertMessage(props) {
  
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      z-index="22"
      transform="translate(-50%, -50%)"
    >
      <Alert status={props.status} w="300px" textAlign="center" mx="auto" variant="subtle" borderRadius="md" >
        <AlertIcon />
        {props.message}
      </Alert>
    </Box>
  );
}
