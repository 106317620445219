import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

function ForgotPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (status == "success") {
      }
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setDisableButton(true);

    const formData = new FormData(event.target);

    try {
      let response = await makeApiRequest("/forget-password", "POST", formData);

      setShowAlert(true);

      if (response?.status === 200) {
        setStatus("success");
        setMessage("Password reset link has been sent to your email!");
      } else {
        setStatus("error");
        if (response?.response?.status === 422) {
          let errors = response.response.data.errors;

          errors?.email && setMessage(errors?.email[0]);
          setDisableButton(false);
        } else if (response?.response?.status === 400) {
          setMessage(response.response.data.message);
          setDisableButton(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <div className="d-flex justify-content-center w-100">
        <img src={logo} className="img-fluid" alt="logo" />
      </div>
      <Box me="auto">
        <Heading className="" color={textColor} fontSize="36px" mb="10px">
          Forgot Password
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter your email to reset your password.
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleForgotPasswordSubmit}>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={textColorBrand}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              name="email"
              placeholder="johndoe@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
          <Button
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50px"
            mb="24px"
            disabled={disableButton}
          >
            Reset Password
          </Button>
        </form>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <NavLink to="/auth/sign-in">
            <Text color={textColorBrand} fontWeight="500" fontSize="14px">
              Back to Sign In
            </Text>
          </NavLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
