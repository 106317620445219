import { Box, SimpleGrid, Input, InputGroup } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Card from "components/card/Card.js";
import referrals from "../../../variables/referrals.json";
// import profile from "../../../variables/profile.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function Referrals() {
  const [referralCode, setReferralCode] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [showCopiedAlert, setShowCopiedAlert] = useState(false);

  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/referrals", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setReferralCode(responseData?.ref_link);
        setReferrals(responseData?.data);
      }
    } catch (error) {
      // console.log(error);
    }


    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const copyReferralCode = () => {
    // Copy the referral code to the clipboard
    navigator.clipboard.writeText(referralCode);
    setShowCopiedAlert(true);
  };

  useEffect(() => {
    // Hide the copied alert after 2 seconds
    const timer = setTimeout(() => {
      setShowCopiedAlert(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showCopiedAlert]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          
          <div className="upper py-4 d-flex flex-column justify-content-center align-items-center">
            <div className="row w-100 justify-content-center">
              <div className="col-md-8">
                <div className="h6 d-flex justify-content-center">You can refer users by sharing your referral link:</div>
                <InputGroup w="100%" mb="20px">
                  <Input
                    type="text"
                    value={referralCode}
                    isReadOnly
                    disabled
                    pr="3.5rem" // Add padding-right to accommodate the copy icon
                  />
                  <Box
                    as={CopyIcon}
                    h="100%"
                    w="2.0rem"
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={copyReferralCode}
                  />
                </InputGroup>
              </div>
            </div>
            {showCopiedAlert && (
              <Alert message="Referral Code Copied" status="success" />
            )}
            <div className="w-100 d-flex justify-content-center">
              My Referral ID:
              <span className="text-primary fw-bolder ms-2">
                {profile.username == null ? "---" : profile.username}
              </span>
            </div>
            <div className="w-100 d-flex justify-content-center">
              Referred by:{" "}
              <span className="text-primary ms-2">
                {profile.refferal == null ? "---" : profile.refferal}
              </span>
            </div>
          </div>
          <h2 className="h4">Referrals</h2>
          <div className="table-responsive">
            <Table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Date registered</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((item, key) => {
                  let statusBadge = "";

                  switch (item.status) {
                    case "active":
                      statusBadge = "badge text-bg-success";
                      break;
                    case "inactive":
                      statusBadge = "badge text-bg-danger";
                      break;
                    default:
                      statusBadge = "badge text-bg-dark"; // Default color if the status is not recognized
                  }

                  return (
                    <tr key={key}>
                      <td>{item.name}</td>
                      <td>{item.date}</td>
                      <td className="d-flex justify-content-center">
                        <div className={`${statusBadge} p-2`}>
                          {item.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      </SimpleGrid>
      {/* Add the disabled input with copy icon */}
      <SimpleGrid
        columns={1}
        spacing={{ base: "20px", xl: "20px" }}
        justifyContent="center"
      ></SimpleGrid>
    </Box>
  );
}
