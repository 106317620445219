import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Card from "components/card/Card.js";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function Kyc() {
  const [identityCard, setIdentityCard] = useState(null);
  const [passportPhoto, setPassportPhoto] = useState(null);

  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("identity_card", identityCard);
    formData.append("passport", passportPhoto);

    try {
      let response = await makeApiRequest("/upload-kyc", "POST", formData, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Document Upload Successful");

        setPassportPhoto(null);
        setIdentityCard(null);

        // console.log(response);
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.identity_card &&
            setMessage(response.response.data.errors.identity_card[0]);
          response.response.data.errors.passport &&
            setMessage(response.response.data.errors.passport[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card className="min-vh-75" mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">KYC verification</h2>
          <div className="body py-3">
            <Row className="mx-auto justify-content-center">
              <Col md={6}>
                <div className="d-flex justify-content-center bg-warning p-2 mb-3">
                  Upload documents below to get verified.
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Label for="identityCard">
                      Valid Identity Card (docx/pdf)
                    </Label>
                    <Input
                      type="file"
                      id="identityCard"
                      onChange={(e) => setIdentityCard(e.target.files[0])}
                      accept=".docx,.pdf"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="passportPhoto">
                      Passport Photograph (image)
                    </Label>
                    <Input
                      type="file"
                      id="passportPhoto"
                      onChange={(e) => setPassportPhoto(e.target.files[0])}
                      accept="image/*"
                      required
                    />
                  </FormGroup>
                  <Button
                    className="w-100"
                    color="success"
                    type="submit"
                    disabled={
                      profile?.kyc_status == 0
                        ? false
                        : profile?.kyc_status == 1
                        ? true
                        : profile?.kyc_status == 2
                        ? true
                        : null
                    }
                  >
                    {profile?.kyc_status == 0
                      ? "Submit Documents"
                      : profile?.kyc_status == 1
                      ? "Awaiting Approval"
                      : profile?.kyc_status == 2
                      ? "Document Approved"
                      : null}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Card>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
      </SimpleGrid>
    </Box>
  );
}
