import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdMoneyOff,
  MdAttachMoney,
  MdBusiness,
  MdTrendingUp,
  MdCreditCard,
  MdMonetizationOn,
  MdPeople,
  MdHelp,
  MdLock
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import InvestmentHistory from "views/admin/investments/investmentHistory";
import Transactions from "views/admin/transactions"
import ProfitRecords from "views/admin/investments/profitRecords";
import TransferFunds from "views/admin/funds/transfer";
import NewPlans from "views/admin/investments/newPlans";
import Deposit from "views/admin/funds/deposit";
import Withdrawals from "views/admin/funds/withdrawal";
import Kyc from "views/admin/account/kyc";
import Profile from "views/admin/account";
import Support from "views/admin/account/support";
import Referrals from "views/admin/account/referrals";


// Auth Imports
import SignInCentered from "views/auth/signin";
import SignUp from "views/auth/signup";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";
import EmailVerification from "views/auth/verify-email";
import Unverified from "views/auth/unverified";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/overview",
    icon: <MdHome width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Deposit",
    layout: "/admin",
    icon: <MdMoneyOff width="20px" height="20px" color="inherit" />,
    path: "/deposit",
    component: Deposit,
  },
  {
    name: "Transfer Funds",
    layout: "/admin",
    icon: <MdAttachMoney width="20px" height="20px" color="inherit" />,
    path: "/transfer-funds",
    component: TransferFunds,
  },
  {
    name: "Withdrawals",
    layout: "/admin",
    icon: <MdBusiness width="20px" height="20px" color="inherit" />,
    path: "/withdrawals",
    component: Withdrawals,
  },
  {
    name: "Subscribe to a Plan",
    layout: "/admin",
    icon: <MdTrendingUp width="20px" height="20px" color="inherit" />,
    path: "/buy-plan",
    component: NewPlans,
  },
  {
    name: "Profit Records",
    layout: "/admin",
    icon: <MdCreditCard width="20px" height="20px" color="inherit" />,
    path: "/profit-records",
    component: ProfitRecords,
  },
  {
    name: "Investment History",
    layout: "/admin",
    icon: <MdMonetizationOn width="20px" height="20px" color="inherit" />,
    path: "/investment-history",
    component: InvestmentHistory,
  },
  {
    name: "Transaction History",
    layout: "/admin",
    icon: <MdPeople width="20px" height="20px" color="inherit" />,
    path: "/transactions",
    component: Transactions,
  }, 
  {
    name: "KYC",
    layout: "/admin",
    icon: <MdBarChart width="20px" height="20px" color="inherit" />,
    path: "/kyc",
    component: Kyc,
  },
  {
    name: "Referrals",
    layout: "/admin",
    icon: <MdPeople width="20px" height="20px" color="inherit" />,
    path: "/referrals",
    component: Referrals,
  },
  {
    name: "Account Settings",
    layout: "/admin",
    path: "/profile",
    icon: <MdPerson width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Help/Support",
    layout: "/admin",
    icon: <MdHelp width="20px" height="20px" color="inherit" />,
    path: "/support",
    component: Support,
  },
  
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUp,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ForgotPassword,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
  },
  {
    name: "Verify Email",
    layout: "/auth",
    path: "/verification",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: EmailVerification,
  },
  {
    name: "Unverified Email",
    layout: "/auth",
    path: "/email-unverified",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Unverified,
  },
];

export default routes;
